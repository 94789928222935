<template>
  <div>
    <div class="box">
      从来没有真正的绝境, <br>只有心灵的迷途~<br>acha.&nbsp;&nbsp;&nbsp;2022.12.06
    </div>
    <footer>
      <a class="link"
         target="_blank"
         href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37132402371512"><img src="./images/icon01.png" />鲁公网安备 37132402371512号</a>
      
      &nbsp;
         <a class="link"
         target="_blank"
         href="https://beian.miit.gov.cn">鲁ICP备2022014171号</a>
    </footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-size : 14px;
  background: url(./images/bg.png) no-repeat;
  background-size: over;
}
.box {
 position: fixed;
  top: 40%;
  left: 50%;
  font-size:50px;
  color: #fff;
  transform: translate(-50%, -50%);
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
  background-color: rgb(78, 78, 78);
}
img {
  vertical-align: middle;
}
.link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.link:hover {
  color: #cecece;
}
@media all and (max-width:414px) {
  .box{
    font-size: 21px;
  }
}
</style>